import React from "react"
// import { AnchorLink } from "gatsby-plugin-anchor-links";

export default function HeadBlock(props) {
  return <> 
  <div className="head-block-container" style={{ backgroundImage: `url(${props.banner})`}}>
    <div className="container">
      {/* <h5 className="head-block-container-pretitle">{props.pretitle}</h5> */}
      <h1 className="head-block-container-title">{props.title}</h1>
    </div>
  </div>
  </>
}