import React from "react"
import "../../scss/index.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Layout from "../../components/layout/layout"
import Header from "../../components/layout/header"
import Footer from "../../components/layout/footer"
import CaseStudy from "../../../static/img/case-study.jpg"
import { Helmet } from "react-helmet"
import HeadBlock from "../../components/head-block/head-block";

export default function Home() {
  return <>
  <Helmet>
          <meta charSet="utf-8" />
          <title>Eco Wash Solutions | Anywhere Anytime We Come To You</title>
          <link rel="canonical" href="https://ecocarwash.co.nz" />
        </Helmet>
  <Header />
  <Layout>
  {/* Head Block */}
  <HeadBlock banner={CaseStudy} pretitle="Modern Edquipment" title="Case Study - Cake Commercial" description="Anywhere Anytime We Come to You"  />
   {/* Intro Block */}
   <div className="container">
      <h3 className="heading">Eco Wash Solutions Delivers Cost-Effective and Efficient Solution for Paint Correction</h3>
      <h6 className="sub-heading sub-heading--basic">Background:</h6>
      <p>In 2019, Eco Wash Solutions was approached by Cake Commercial for a large project in Auckland. The challenge was that due to windy conditions, cars parked in a nearby car park were covered with paint spray from a building being painted. This resulted in significant damage to the cars, with each requiring a costly repainting that would have been a financial burden for the owners.</p>
      <h6 className="sub-heading sub-heading--basic">Solution:</h6>
      <p>Eco Wash Solutions provided a solution that involved a clay bar treatment to correct the paint damage without causing any further damage to the paint. This approach was not only effective in correcting the paint damage, but it also proved to be a cost-effective alternative to the costly repainting of the cars. The Eco Wash team worked tirelessly to complete the project within a week, ensuring that the cars were restored to their original condition.</p>
      <h6 className="sub-heading sub-heading--basic">Result:</h6>
      <p>Approximately 70 cars were corrected for a fraction of the cost that the car owners would have incurred for a full repainting, demonstrating Eco Wash Solutions' commitment to providing affordable and efficient solutions for its clients. By delivering the project within a week, Eco Wash Solutions also showcased its expertise and ability to work efficiently in challenging conditions.</p>
      <h6 className="sub-heading sub-heading--basic">Conclusion:</h6>
      <p>This case study highlights the value of Eco Wash Solutions' innovative approach to solving challenging problems. By using a clay bar treatment to correct the paint damage, the company not only saved the car owners significant amounts of money, but it also demonstrated its ability to work effectively in adverse weather conditions. Eco Wash Solutions' ability to provide cost-effective, efficient, and innovative solutions to its clients makes it a reliable and trusted partner for any paint correction project.</p>
      <AnchorLink to="/#contact" title="Contact Us" className="button button--large button--primary">Get in touch for a customised quote</AnchorLink>
      </div>
  </Layout>
  <Footer copyright="Copyright &copy; 2020 - 2023 Eco Wash Solutions. All Rights Reserved" />
  </>
}
